import React, { useCallback, useEffect, useState, useTransition } from 'react';
import Box from '@mui/material/Box';
import SquareItem from "../components/Square";
import { MDBAnimation, MDBIcon } from "mdbreact";
import { BetButton } from "../components/BetButton";
import { useLang } from "../hooks/lang.hook";
import { Balance } from "../components/Balance";
import { useSpring, animated } from "react-spring";
import { manager } from "../services/socket";
import { BombHistoryList } from "../components/BombHistory";
import useCurrency from "../hooks/CurrencyHook";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import {Typography, useMediaQuery} from "@mui/material";
import {Button} from "../components/Button";
import useAuth from "../hooks/AuthHook";
import {enqueueSnackbar} from "notistack";


const BombsPage = () => {
  manager._autoConnect = false
  const socketGame = manager.socket('/bombs');
  const [connect, setConnect] = useState(false)
  const [my_swiper, set_my_swiper] = useState({})
  const matches = useMediaQuery("(min-width:1300px)", { noSsr: true });
  const { strings } = useLang();
  const { setUser } = useAuth();
  const [squares, setSquares] = useState(25);
  const { currency } = useCurrency()
  const [historyItems, setHistoryItems] = useState([]);
  const [areas, setAreas] = useState([])
  const [clearsSquare, setClearsSquare] = useState([]);
  const [bombsSquare, setBombsSquare] = useState([]);

  const [bombs, setBombs] = useState(3)
  const [inputBet, setInputBet] = useState(1);
  const [potentialWin, setPotentialWin] = useState(0);

  const [coffs, setCoffs] = useState([])
  const [lose, setLose] = useState(false)
  const [status, setStatus] = useState(-1);

  const swiper = useSwiper();
  const [swip, setSwip] = React.useState();

  const transitions = useSpring({
    from: { y: status === 0 ? 100 : 0, opacity: status === 0 ? 0 : status === -1 && 0 },
    to: { y: status === 0 ? 0 : 100, opacity: status === 0 ? 1 : 0 },
  })
  const transitionsBtn = useSpring({
    from: { y: status === 0 ? -100 : 0, opacity: status === 0 ? 0 : status === -1 && 0, },
    to: { y: status === 0 ? 0 : -100, opacity: status === 0 ? 1 : 0 },
  })
  const { x } = useSpring({
    from: { x: 0 },
    x: lose ? 1 : 0,
    config: { duration: 1000 },
  })

  const [betValid, setBetValid] = useState({
    message: false,
    success: true
  })

  const setBet = (value) => {
    setInputBet(+inputBet + value)
  }
  const onChangeBet = (event) => {
    setInputBet(event.target.value)
  }

  const addBet = () => {
    setAreas([]);
    setClearsSquare([]);
    setBombsSquare([]);
    setPotentialWin(0);
    setLose(false);
    socketGame.emit('createGame', {
      bet: inputBet,
      bombs
    }, (callback) => {

      if(callback.success) {
        setStatus(callback.status)
        setAreas(callback.userArea);
        return true;
      } else {
        enqueueSnackbar(strings.responseCodes[callback.code], {
          title: callback.success ? strings.responseCodes.success : strings.responseCodes.error,
          variant: callback.success ? 'successAlert' : 'errorAlert' ,
        })
      }
    })
  }

  const handleCheck = async (id) => {
    if(clearsSquare.includes(id)) {
      return false
    }
    socketGame.emit('checkSquare', {
        id
      },
      async (callback) => {
        if(callback.success) {
          my_swiper.slideNext();
          setPotentialWin(callback.potentialWin);
          setClearsSquare(prev => [...prev, id]);
        } else {
          setBombsSquare(prev => [...prev, id]);
          setPotentialWin(0);
          setStatus(-1);
          my_swiper.slideTo(0);
          setLose(true);
        }
      })
  }
  const endGame = () => {
    socketGame.emit('endGame', {}, (callback) => {
      if(callback.success) {
        setAreas([]);
        setClearsSquare([]);
        setBombsSquare([]);
        setLose(false);
        setStatus(-1);
        my_swiper.slideTo(0)
      }
    })
  }
  const onChangeBomb = (event) => {
    const res = event.target.value.replace(/\D/g, '');
    if (res) {
      if (res > 24) {
        return false
      }
    }
    setBombs(res)
  }

  const getCof = () => {
    let t = [];
    let n = squares - bombs;
    let m = 0 / 100;

    if (bombs > 24 || bombs < 3) {
      return false
    }

    for (let s = 1, r = 0; r < n; r += 1) {
      s *= (squares - r) / (squares - bombs - r);
      let q = s - s * m;
      let o = Math.floor(100 * (q)) / 100;
      t.push(o)
    }
    setCoffs(t)
  }

  useEffect(() => {
    socketGame.connect()
    socketGame.on('connect', () => {
    })
    socketGame.emit('reconnect');
    socketGame.on('refreshGame', (socket) => {
      setInputBet(socket.bet)
      setBombs(socket.bombs)
      setClearsSquare(socket.userArea)
      setPotentialWin(socket.potentialWin)
      setStatus(socket.status)
    })
    socketGame.on('sendHistory', (socket) => {
      setHistoryItems(prev => [socket, ...prev]);
    })
    socketGame.emit('getHistory', {},(callback) => {
      if(callback.success) {
        setHistoryItems(callback.data)
      }
    })
   return () => {
     socketGame.disconnect()
   }
  }, [])
  useEffect(() => {
    getCof()
  }, [bombs, inputBet])

  return (
    <Box sx={{
      height: '100%',
      mt: '10px',
    }} className={'scrollbar scrollbar-primary bombs overflow-auto'}>
      {
        !matches && (
          <Box sx={{ display: 'flex', justifyItems: 'center', gap: '10px', alignItems: 'center', margin: '50px'}}>
            <MDBIcon className={'color-text-sm'} size={'lg'} onClick={() => swip.slidePrev()} icon="chevron-left" />
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={10}
              slidesPerView={3}
              direction={"horizontal"}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onInit={(ev) => {
                set_my_swiper(ev)
              }}
              onSlideChange={() => console.log("slide change")}
            >
              {
                coffs.map((el, index) =>
                  <SwiperSlide
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{
                      display: "grid",
                      padding: '5px 10px',
                      gap: '3px',
                      width: "100%",
                      alignItems: "center",
                      height: "60px",
                      borderRadius: "6px",
                      border: clearsSquare.length === index && '1px solid #00CD39',
                      fontSize: clearsSquare.length === index && '20px',
                    }} className={"mainPanelColor"}>
                      <div>
                        <Typography sx={{ fontSize: '14px', color: 'white', display: 'grid', gap: '10px', gridAutoFlow: 'column',justifyContent: 'space-between', alignItems: 'center'}}>
                          <Typography className={'color-text-md'} sx={{ display: 'grid', gap: '5px', gridAutoFlow: 'column', alignItems: 'center'}}><strong className={'textMain'}>{index + 1}</strong><MDBIcon className={'color-text-lg'} icon={'bomb'}/></Typography>
                          <strong className={'color-text-lg'}>x{el}</strong>
                        </Typography>
                      </div>
                      <Balance value={el * inputBet}/>
                    </Box>
                  </SwiperSlide>
                )
              }
            </Swiper>
            <MDBIcon className={'color-text-sm'} size={'lg'} onClick={() => swip.slideNext()} icon="chevron-right" />
          </Box>
        )
      }
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(170px, auto))',
        gap: '20px',
        }} className={'bombContainer'}>
        <div className="game__form d-flex justify-content-center flex-column align-items-center flex-wrap" style={{ zoom: '0.9'}}>
          <Box className="bet__buttons" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(100px, 100%))', width: '100%', justifyContent: 'center'}}>
            <BetButton style={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} onClick={() => status === -1 && setBet(1)} value={1}/>
            <BetButton style={{ display: 'flex', justifyContent: 'center'}} onClick={() => status === -1 && setBet(5)} value={5}/>
            <BetButton style={{ display: 'flex', justifyContent: 'center'}} onClick={() => status === -1 && setBet(10)} value={10}/>
            <BetButton style={{ display: 'flex', justifyContent: 'center'}} onClick={() => status === -1 && setBet(25)} value={25}/>
            <BetButton style={{ display: 'flex', justifyContent: 'center'}} onClick={() => status === -1 && setBet(50)} value={50}/>
            <BetButton style={{ display: 'flex', justifyContent: 'center'}} onClick={() => status === -1 && setBet(100)} value={100}/>
          </Box>
          <hr style={{ height: '0px', width: '100%'}} className="room__active"/>
          <Box sx={{ display: 'grid', gap: '20px'}}>
            <Box className="game__bet-form mainPanelColor" sx={{ display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: '20px'}}>
              <Box className="color-text-lg input-r" htmlFor="betForm" padding={"0 20px"}>
                { currency === 'usd' ? <MDBIcon fas icon="dollar-sign"/> : <MDBIcon fas icon="ruble-sign"/> }
              </Box>
              <label className="form__label" htmlFor="betForm">
                {!betValid.success &&
                  <MDBAnimation type="bounceIn">
                  </MDBAnimation>
                }
              </label>
              <input style={{ padding: 0}}  disabled={status !== -1}  id="betForm" value={inputBet} onChange={onChangeBet} type="text" placeholder="Своя ставка"
                     className={betValid.success ? "bet__input mainPanelColor m-0" : "bet__input mainPanelColor errInput"}/>
            </Box>
            <Box className="game__bet-form mainPanelColor" sx={{ display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: '20px'}}>
              <Box className="color-text-lg input-r" htmlFor="bombForm" padding={'0 20px'}>
                <MDBIcon icon={'bomb'}/>
              </Box>
              <input style={{ padding: 0}} disabled={status !== -1} id="bombForm" value={bombs} onChange={onChangeBomb} type="text"
                     placeholder="Кол-во бомб"
                     className={betValid.success ? "bet__input  m-0" : "bet__input mainPanelColor errInput"}/>
            </Box>
          </Box>
          <hr style={{ height: '0px', width: '100%'}} className="room__active"/>
          <div
            style={{ width: '100%', textAlign: 'center', margin: 0}}
            className={status !== -1 ? "disabled button-dis button my-text-dark font-weight-bold" : " button my-text-dark font-weight-bold"}
            onClick={addBet}>
            <MDBIcon icon="coins"/> Начать игру
          </div>
        </div>
        <Box className={'bombPlace'} sx={{ display: 'grid', justifyItems: 'center', alignSelf: 'start'}}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, minmax(44px, 88px))',
              gridTemplateRows: 'repeat(5, 81px)',
              gap: '22px',
              position: 'relative',
              transition: 'all 0.5s',
            }}>
            {
              [...Array(squares)].map((el, index) => (
                <SquareItem index={index} clear={clearsSquare.includes(index)} bomb={bombsSquare.includes(index)} handleCheck={status === 0 && handleCheck}>
                </SquareItem>
              ))
            }
          </Box>
          {
            <animated.div
              style={transitionsBtn}
              onClick={endGame}>
              <Button>
                <MDBIcon icon="coins"/> { strings.bombs.getWin }
                <Balance className={'my-text-dark'} w={'30px'} h={'30px'} value={potentialWin}/>
              </Button>
            </animated.div>
          }
        </Box>
        {
          matches && (
            <Box sx={{ display: 'grid', justifyItems: 'center', gap: '10px'}}>
              <MDBIcon className={'color-text-sm'} size={'lg'} onClick={() => swip.slidePrev()} icon="chevron-up" />
              <Swiper
                style={{ width: "152px", height: "358px" }}
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={5}
                direction={"vertical"}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onInit={(ev) => {
                  set_my_swiper(ev)
                }}
                onSlideChange={() => console.log("slide change")}
              >
                {
                  coffs.map((el, index) =>
                    <SwiperSlide
                      style={{
                        display: "grid",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{
                        display: "grid",
                        padding: '5px 10px',
                        gap: '3px',
                        width: "150px",
                        alignItems: "center",
                        height: "60px",
                        borderRadius: "6px",
                        border: clearsSquare.length === index && '1px solid #00CD39',
                        fontSize: clearsSquare.length === index && '20px',
                      }} className={"mainPanelColor"}>
                        <div>
                          <Typography sx={{ fontSize: '14px', color: 'white', display: 'grid', gap: '10px', gridAutoFlow: 'column',justifyContent: 'space-between', alignItems: 'center'}}>
                            <Typography className={'color-text-md'} sx={{ display: 'grid', gap: '5px', gridAutoFlow: 'column', alignItems: 'center'}}><strong className={'textMain'}>{index + 1}</strong><MDBIcon className={'color-text-lg'} icon={'bomb'}/></Typography>
                            <strong className={'color-text-lg'}>x{el}</strong>
                          </Typography>
                        </div>
                        <Balance value={el * inputBet}/>
                      </Box>
                    </SwiperSlide>
                  )
                }
              </Swiper>
              <MDBIcon className={'color-text-sm'} size={'lg'} onClick={() => swip.slideNext()} icon="chevron-down" />
            </Box>
          )
        }
      </Box>
      <BombHistoryList items={historyItems} />
    </Box>
  );
};

export default BombsPage;