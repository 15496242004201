import React, {useEffect, useState} from 'react'
import {MDBAnimation, MDBIcon} from 'mdbreact'
import {Balance} from "../components/Balance"
import { RouletteV2 } from '../components/Roulette'
import {Livedrop} from '../components/Livedrop'
import {BetButton} from "../components/BetButton";
import {BetUser} from "../components/BetUser";
import {toast} from "../components/Toast/toast"
import {Loader} from "../components/Loader";
import {motion} from "framer-motion"
import {Timer} from "../components/Timer";
import useLang from "../hooks/lang.hook";
import { manager } from "../services/socket";
import { useSnackbar } from "notistack";


export const MainPage = ({ room }) => {
  const socketGame = manager.socket('/game');
  const betsRef = React.createRef();
  const scrollRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  const { strings } = useLang();
  const [heightBets, setHeightBets] = useState(0);
  const [showBetsState, setShowBetsState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputBet, setInputBet] = useState(0);
  const [historyState, setHistoryState] = useState([]);
  const [currentGame, setCurrentGame] = useState({
    bets: [],
    users: [],
    rollDate: '',
    winner: {
      winnerBet: {}
    },
    maxSum: 0,
    currentBank: 0
  })

  const [allBets, showAllBets] = useState(false)
  const [status, setStatus] = useState(0)
  const [betValid, setBetValid] = useState({
    message: false,
    success: true
  })
  const [showWinner, setShowWinner] = useState(false)
  const [start, setStart] = useState(false)

  useEffect(()=> {
    socketGame.on('getPercent', (socket) => {
      // props.getPercent(socket)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    connectRoom(room)
  }, [room])

  useEffect(()=> {
    socketGame.on('getHistory', (history) => {
      setHistoryState(history)
    })
  },[])

  const connectRoom = (room) => {
    socketGame.emit('connectInRoom', {new: room, current: room}, (gameData) => {
      setStart(false)
      setShowWinner(false)
      setCurrentGame(prev => ({...prev, ...gameData}))
      setLoading(false)
    })
  }

  useEffect(()=> {
    socketGame.on('initGame', (game) => {
      setCurrentGame(game)
      setShowWinner(false)
    })
  },[room])



  // HANDLE ROLL
  useEffect( () => {
    socketGame.on('roll', (socket)=> {
      setCurrentGame(socket)
    })
  }, [])

  useEffect(()=> {
    setHeightBets(1)
    if(scrollRef.current.offsetHeight > betsRef.current.offsetHeight) {
      setShowBetsState(true)
    } else {
      setShowBetsState(false)
    }
  }, [heightBets])

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)
  },[])
  // GET BETS
  useEffect(() => {
    socketGame.on('handleBet', (socket) => {
      document.title = `7Rio - ${socket.currentBank.toFixed(2)}`
      setCurrentGame(socket)
      setStatus(socket.status)
    })
  },[])
  useEffect(() => {
    socketGame.on('message', (socket) => {
      toast({
        success: true,
        winner: true,
        message: socket
      })
    })
  },[null])
  const handleResize = () => {
    setHeightBets(Math.random())
  }
  const showBets = () => {
    showAllBets(!allBets)
  }

  const setBet = (value) => {
    setInputBet(+inputBet + value)
  }
  const onChangeBet = (event) => {
    setInputBet(event.target.value)
  }

  const addBet = () => {
    setDisabled(true)
    socketGame.emit('addBet', {
      room: room,
      bet: inputBet
    }, (res) => {
      if(!res.success) {
        enqueueSnackbar(strings.responseCodes[res.code], {
          title: strings.responseCodes.error,
          variant: 'errorAlert',
        })
      }
      setBetValid(res)
      setDisabled(false)
      setTimeout(()=> setBetValid({
        message: false,
        success: true
      }),3000)
    })
  }

  return (
    <div className="main-panel scroll-mobile flex-1 overflow-hidden d-flex flex-column position-relative" >
      <Loader hidden={!loading} />
      <div className="livedrop overflow-hidden mt-1 mb-2 justify-content-start">
        {historyState && historyState.map((game, key) => <Livedrop key={key} user={game.winnerId} bank={game.sum}/>)}
      </div>
      <div className="game overflow-hidden mainColor d-flex flex-column">
        { showWinner && currentGame.winner.winnerBet.color &&
          <motion.div
            animate={{ y: 0}}
            transition={
              {y: { from: 100,type: "spring", stiffness: 100 },
              }
            }
            className="game__stats">
            <div className="game__stats-user d-flex align-items-center justify-content-center flex-row flex-wrap">
              <div className="avatar w-20 m-0">
                <img className="avatar__img" src={currentGame.winner.winnerBet.avatar} alt=""/>
              </div>
              <span className="ml-2 mr-2" ><span className="textMain">{currentGame.winner.winnerBet.username}</span></span>
              <p className="d-flex align-items-center m-0 color-text-lg">{strings.game.win[0]} <Balance className="m-2" value={currentGame.currentBank}/> {strings.game.win[1]} {currentGame.winner.winnerBet.percent && currentGame.winner.winnerBet.percent.toFixed(2)}%</p>

            </div>
          </motion.div>
        }
        {!showWinner &&
          <MDBAnimation type={!showWinner ? "slideInUp" : "slideOutUp"} className="game__stats d-flex align-items-center">
            <Balance h={20} w={20} className="game__bank" value={currentGame.currentBank}/>
            <div className="h24 ml-3 mr-3">|</div>
            <Timer game={currentGame} status={currentGame.status} value={currentGame.timer}/>
          </MDBAnimation>
        }
        <div className="game__roulette">
          <RouletteV2 gameStatus={currentGame.status} rollDate={currentGame.rollDate} room={room} socketGame={socketGame} finishHandle={setShowWinner} showWinner={showWinner} setShowWinner={setShowWinner} bets={currentGame?.users} winner={currentGame.winner.winnerBet} maxSum={currentGame.maxSum}/>
          {/*<Roulette room={room} bets={currentGame?.bets} duration={{duration, setDuration}} start={currentGame.status === 2} setStart={setStart}  showWinner={{winner: showWinner, set:(state) => setShowWinner(state)}} maxSum={currentGame.maxSum} winner={currentGame.winner.winnerBet?.color} currentUsers={currentGame.users}/>*/}
        </div>
        <div className="game__form d-flex justify-content-center align-items-center flex-wrap">
          <div className="game__bet-form">
            <label className="form__label" htmlFor="betForm">
              {!betValid.success &&
                <MDBAnimation type="bounceIn">
                </MDBAnimation>
              }
            </label>
            <input id="betForm" value={inputBet} onChange={onChangeBet} type="text" placeholder="Своя ставка" className={betValid.success ? "bet__input mainPanelColor" : "bet__input mainPanelColor errInput"}/>
          </div>
          <div className={disabled ? "disabled button-dis button my-text-dark font-weight-bold mr-3" : "mr-3 button my-text-dark font-weight-bold" } onClick={addBet}>
            <MDBIcon icon="coins" /> {strings.game.bet}
          </div>
          <div className="bet__buttons">
            <BetButton onClick={()=> setBet(5)} value={5}/>
            <BetButton onClick={()=> setBet(10)} value={10}/>
            <BetButton onClick={()=> setBet(25)} value={25}/>
            <BetButton onClick={()=> setBet(50)} value={50}/>
          </div>
        </div>
        <div className="game__borders d-flex justify-content-between align-items-center">
          <hr className="game__hr"/>
          <MDBIcon icon="user-plus"  className="sidebar__icon-active mr-2"/><strong className="textMain">{currentGame.users.length}/{currentGame.maxPlayers}</strong>
          <hr className="game__hr"/>
        </div>
        <div ref={betsRef} className="flex-1 overflow-hidden">
          <div ref={scrollRef} className={allBets ? "game__bets game__bets-all flex-start overflow-auto scrollbar scrollbar-primary" : "game__bets overflow-auto scrollbar scrollbar-primary"}>
            {currentGame.bets.length === 0 && <div className="color-text-lg d-flex justify-content-center align-items-center w-100">{strings.game.empty}</div>}
            {currentGame.bets.map(
              (bet, key) =>
                <motion.div
                  key={key}
                  animate={{
                    y: 0,
                    width:['0%', '100%'],
                    scale: [0, 1]
                  }}
                  transition={{
                    y: {
                      type: "spring",
                      stiffness: 102,
                      duration: 1,
                      from: 100
                    }
                  }}
                  className={'col-lg-3 col-md-4 col-sm-6 p-0 col-6'}><BetUser user={bet}/></motion.div>
            )}
            {showBetsState &&
              <div className={allBets ? "d-none game__bets-overflow color-text-lg justify-content-center align-items-center" : "game__bets-overflow color-text-lg d-flex justify-content-center align-items-center"}>
                <span onClick={() =>(showBets())} className="game__bets-show">{strings.game.showAll}</span>
              </div>}
          </div>
        </div>
      </div>
    </div>
  )
}