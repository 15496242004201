import React, {useEffect, useState} from 'react'
import { MDBIcon, MDBBtn } from "mdbreact";
import { NavLink } from "react-router-dom";
import { useTrail, animated } from "react-spring";
import { Desktop, Tablet } from '../Responsive'
import useLang from "../../hooks/lang.hook";
import {motion} from "framer-motion";

export const Sidebar = ({ visual, openModalPayments, openModalSupport, online }) => {
  const { currentLang } = useLang();
  const items = [
    {
      to: '/',
      name: {
        ru: 'Рулетка',
        en:'Roulette',
      },
      icon: <MDBIcon icon="dice" className="sidebar__icon color-text-md"/>,
    },
    {
      to: '/bombs',
      name: {
        ru: 'Бомбы',
        en:'Bombs',
      },
      icon: <MDBIcon icon="bomb" className="sidebar__icon color-text-md"/>,
    },
    {
      to: '/faq',
      name: {
        ru: 'FAQ',
        en:'FAQ',
      },
      icon: <MDBIcon icon="info-circle" className="sidebar__icon color-text-md"/>
    },
    {
      to: '/referrals',
      name: {
        ru: 'Рефералы',
        en:'Referrals',
      },
      icon: <MDBIcon icon="user-plus" className="sidebar__icon color-text-md"/>,
    },
    {
      to: '/bonus',
      name: {
        ru: 'Бонусы',
        en:'Bonus',
      },
      icon: <MDBIcon icon="gift" className="sidebar__icon color-text-md"/>,
    },
    {
      to: '/promo',
      name: {
        ru: 'Промокоды',
        en:'Promo',
      },
      icon: <MDBIcon icon="ticket-alt" className="sidebar__icon color-text-md" />
    },
    {
      to: '#',
      name: {
        ru: 'Депозит',
        en:'Deposit',
      },
      onClick: () => openModalPayments(prev => !prev),
      icon: <MDBIcon icon="dollar-sign" className="sidebar__icon color-text-md"/>,
    },
    {
      to: '#',
      name: {
        ru: 'Поддержка',
        en:'Support',
      },
      onClick: () => openModalSupport(prev => !prev),
      icon: <MDBIcon icon="headset" className="sidebar__icon color-text-md"/>,
    },
    // {
    //   to: '#',
    //   name: {
    //     ru: 'Чат',
    //     en:'Chat',
    //   },
    //   onClick: () => openChat(prev => !prev),
    //   icon: <MDBIcon icon="comments" className="sidebar__icon color-text-md" />
    // },
  ]
  const trail = useTrail(items.length,{
    from: { opacity: 0, x: 20},
    opacity: 1,
    config: { duration: 200 }
  });

  return (
    <div className="sidebar mainPanelColor">
      <div className="sidebar__items w-100">
        <div style={{ height: '70px' }} className="chat__online ml-auto mr-2 p-1 d-flex flex-center">
          <motion.div
            className='chat__circle connected'></motion.div>

          <span className="textMain">{online}</span>
        </div>
        {
          trail.map(({opacity}, i) => {
            const item = items[i];
            return (
              <animated.div style={{opacity}} onClick={item.onClick} key={item}>
                <NavLink style={{textAlign: "center"}} to={item?.to} className="sidebar__item">
                  {item.icon}
                  <br/>
                  <strong>{item.name[currentLang]}</strong>
                </NavLink>
              </animated.div>
            )
          })
        }
      </div>
      <div className="social align-items-center d-flex justify-content-center flex-column">
        <Desktop>
          <MDBBtn href={visual?.tg} tag="a" target="_blank" color="primary" className="socialBtn">
            <MDBIcon fab icon="telegram-plane"/>
          </MDBBtn>
        </Desktop>
        <Tablet>
          <MDBBtn tag="a" href={visual?.tg} target="_blank" color="primary" className="socialBtn">
            <MDBIcon fab icon="telegram-plane"/>
          </MDBBtn>
        </Tablet>
      </div>
    </div>
  )
}